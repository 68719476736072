import type {DetailedHTMLProps, ImgHTMLAttributes} from 'react'
import React from 'react'

interface ImageWithCaption extends DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  src?: string
  alt?: string
  title?: string
}

export const ImageWithCaption: React.FC<ImageWithCaption> = ({src, alt, title, ...props}) => {
  if (title) {
    return (
      <figure className="text-center text-sm">
        <img src={src} alt={alt} {...props} />
        <figcaption>{title}</figcaption>
      </figure>
    )
  }

  return <img src={src} alt={alt} {...props} />
}
