import posthog from 'posthog-js'
import {createContext, useContext} from 'react'

export type PostHog = typeof posthog

const PostHogContext = createContext<{client: PostHog}>({client: posthog})

export function PostHogProvider({children, client}: {children: React.ReactNode; client: PostHog}) {
  return <PostHogContext.Provider value={{client}}>{children}</PostHogContext.Provider>
}

export function usePostHog(): PostHog {
  const {client} = useContext(PostHogContext)
  return client
}
